@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  /*  text-shadow: rgba(0, 0, 0, 0.1) 0 0 1px; /* Not necessary, but can help with low-PPI screens & windows */
}

.form-input {
  @apply block mt-2 w-full px-3 py-2 rounded-lg border border-gray-300 placeholder-gray-700 bg-gray-100 text-gray-900;
  @apply focus:ring-2 focus-visible:ring-2 focus:ring-primary-500 focus-visible:ring-primary-500 focus-visible:outline-none;
}

.form-checkbox-toggle {
  @apply w-11 h-6 bg-gray-300 rounded-full peer-focus:ring-2 peer-focus:ring-primary-500 peer-checked:bg-primary-300 border border-gray-300 box-content relative transition-all;
}

.form-checkbox-toggle::after {
  @apply absolute w-5 h-5 left-0.5 top-0.5 bg-white peer-checked:translate-x-full peer-checked:bg-primary-700 rounded-full shadow-mini transition-all;
  content: '';
}

body {
  @apply bg-gray-200 text-gray-900;
}

/* TODO: remove once images are fixed, this is here just so that the images don't look as bad without src */
img::before {
  @apply sr-only;
}
