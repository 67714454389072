*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #f4f4f5;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Source Sans Pro, Roboto, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline:  auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.inset-y-3 {
  top: .75rem;
  bottom: .75rem;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.-left-6 {
  left: -1.5rem;
}

.left-\[-1px\] {
  left: -1px;
}

.-top-2 {
  top: -.5rem;
}

.z-50 {
  z-index: 50;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-10 {
  z-index: 10;
}

.m-2 {
  margin: .5rem;
}

.m-4 {
  margin: 1rem;
}

.m-auto {
  margin: auto;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: .25rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-4 {
  height: 1rem;
}

.h-full {
  height: 100%;
}

.h-8 {
  height: 2rem;
}

.h-48 {
  height: 12rem;
}

.h-12 {
  height: 3rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[72px\] {
  height: 72px;
}

.h-32 {
  height: 8rem;
}

.h-64 {
  height: 16rem;
}

.h-screen {
  height: 100vh;
}

.h-16 {
  height: 4rem;
}

.h-0 {
  height: 0;
}

.min-h-\[4rem\] {
  min-height: 4rem;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.w-4 {
  width: 1rem;
}

.w-16 {
  width: 4rem;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.w-12 {
  width: 3rem;
}

.w-6 {
  width: 1.5rem;
}

.w-32 {
  width: 8rem;
}

.min-w-1\/2 {
  min-width: 50%;
}

.min-w-0 {
  min-width: 0;
}

.max-w-\[960px\] {
  max-width: 960px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-grow {
  flex-grow: 1;
}

.basis-0 {
  flex-basis: 0;
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes enter {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-enter {
  animation: enter .1s ease-out;
}

@keyframes leave {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animate-leave {
  animation: leave .1s ease-in forwards;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.overflow-y-auto {
  overflow-y: auto;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-b-lg {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(171 177 186 / var(--tw-border-opacity) );
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 216 221 / var(--tw-border-opacity) );
}

.border-primary-900 {
  --tw-border-opacity: 1;
  border-color: rgb(0 74 38 / var(--tw-border-opacity) );
}

.border-primary-500 {
  --tw-border-opacity: 1;
  border-color: rgb(82 224 156 / var(--tw-border-opacity) );
}

.border-error-500 {
  --tw-border-opacity: 1;
  border-color: rgb(241 73 73 / var(--tw-border-opacity) );
}

.bg-primary-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 245 205 / var(--tw-bg-opacity) );
}

.bg-error-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 219 219 / var(--tw-bg-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(171 177 186 / var(--tw-bg-opacity) );
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 216 221 / var(--tw-bg-opacity) );
}

.bg-accent-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 215 245 / var(--tw-bg-opacity) );
}

.bg-primary-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 146 79 / var(--tw-bg-opacity) );
}

.bg-transparent-black {
  background-color: #00000026;
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(32 36 43 / var(--tw-bg-opacity) );
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 253 / var(--tw-bg-opacity) );
}

.object-cover {
  object-fit: cover;
}

.object-scale-down {
  object-fit: scale-down;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-1 {
  padding: .25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-2 {
  padding-top: .5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-baseline {
  vertical-align: baseline;
}

.align-middle {
  vertical-align: middle;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-sub {
  vertical-align: sub;
}

.font-display {
  font-family: Inter, Helvetica, sans-serif;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.text-primary-900 {
  --tw-text-opacity: 1;
  color: rgb(0 74 38 / var(--tw-text-opacity) );
}

.text-error-900 {
  --tw-text-opacity: 1;
  color: rgb(136 23 23 / var(--tw-text-opacity) );
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(32 36 43 / var(--tw-text-opacity) );
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(90 100 114 / var(--tw-text-opacity) );
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(171 177 186 / var(--tw-text-opacity) );
}

.text-accent-900 {
  --tw-text-opacity: 1;
  color: rgb(0 47 74 / var(--tw-text-opacity) );
}

.text-primary-100 {
  --tw-text-opacity: 1;
  color: rgb(229 255 243 / var(--tw-text-opacity) );
}

.text-error-700 {
  --tw-text-opacity: 1;
  color: rgb(193 18 18 / var(--tw-text-opacity) );
}

.text-warning-700 {
  --tw-text-opacity: 1;
  color: rgb(227 118 17 / var(--tw-text-opacity) );
}

.text-primary-700 {
  --tw-text-opacity: 1;
  color: rgb(7 146 79 / var(--tw-text-opacity) );
}

.text-warning-900 {
  --tw-text-opacity: 1;
  color: rgb(160 68 16 / var(--tw-text-opacity) );
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.shadow-mini {
  --tw-shadow: 0 1px 1px #0000001a;
  --tw-shadow-colored: 0 1px 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-soft {
  --tw-shadow: 0 1px 4px #0003;
  --tw-shadow-colored: 0 1px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

* {
  webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
}

.form-input {
  width: 100%;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(212 216 221 / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 253 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(32 36 43 / var(--tw-text-opacity) );
  border-radius: .5rem;
  margin-top: .5rem;
  padding: .5rem .75rem;
  display: block;
}

.form-input::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(90 100 114 / var(--tw-placeholder-opacity) );
}

.form-input:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(82 224 156 / var(--tw-ring-opacity) );
}

.form-input:focus-visible {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(82 224 156 / var(--tw-ring-opacity) );
  outline: 2px solid #0000;
}

.form-checkbox-toggle {
  box-sizing: content-box;
  height: 1.5rem;
  width: 2.75rem;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(212 216 221 / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgb(212 216 221 / var(--tw-bg-opacity) );
  border-radius: 9999px;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: relative;
}

.peer:checked ~ .form-checkbox-toggle {
  --tw-bg-opacity: 1;
  background-color: rgb(163 245 205 / var(--tw-bg-opacity) );
}

.peer:focus ~ .form-checkbox-toggle {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(82 224 156 / var(--tw-ring-opacity) );
}

.form-checkbox-toggle:after {
  height: 1.25rem;
  width: 1.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  --tw-shadow: 0 1px 1px #0000001a;
  --tw-shadow-colored: 0 1px 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 9999px;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: .125rem;
  left: .125rem;
}

.peer:checked ~ .form-checkbox-toggle:after {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  --tw-bg-opacity: 1;
  background-color: rgb(7 146 79 / var(--tw-bg-opacity) );
}

.form-checkbox-toggle:after {
  content: "";
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(32 36 43 / var(--tw-text-opacity) );
}

img:before {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.placeholder\:text-gray-700::placeholder {
  --tw-text-opacity: 1;
  color: rgb(90 100 114 / var(--tw-text-opacity) );
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity) );
}

.hover\:bg-accent-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(82 173 224 / var(--tw-bg-opacity) );
}

.hover\:bg-error-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 150 150 / var(--tw-bg-opacity) );
}

.hover\:bg-primary-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 74 38 / var(--tw-bg-opacity) );
}

.hover\:bg-primary-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(82 224 156 / var(--tw-bg-opacity) );
}

.hover\:bg-primary-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(163 245 205 / var(--tw-bg-opacity) );
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(212 216 221 / var(--tw-bg-opacity) );
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(90 100 114 / var(--tw-text-opacity) );
}

@media (min-width: 640px) {
  .sm\:absolute {
    position: absolute;
  }

  .sm\:relative {
    position: relative;
  }

  .sm\:right-4 {
    right: 1rem;
  }

  .sm\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-64 {
    width: 16rem;
  }

  .sm\:min-w-sm {
    min-width: 24rem;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:rounded-b-none {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:text-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .lg\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

/*# sourceMappingURL=index.3518312a.css.map */
